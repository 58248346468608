<template>
  <v-container
    id="admin"
    fluid
    tag="section"
    class="pa-16"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          color="yellow darken-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Relatórios para análise
            </div>
          </template>

          <v-simple-table>
            <thead>
              <tr>
                <th class="primary--text text-center">
                  Data
                </th>
                <th class="primary--text text-center">
                  Hora
                </th>
                <th />
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(report, index) in reports"
                :key="report.id"
              >
                <td
                  class="text-center"
                  @click="loadReport(index)"
                >
                  {{ report.date | date }}
                </td>
                <td
                  class="text-center"
                  @click="loadReport(index)"
                >
                  {{ report.date | time }}
                </td>
                <td class="text-center">
                  <a
                    :href="report.url"
                    target="_blank"
                  >
                    <v-icon color="grey">mdi-link</v-icon>
                  </a>
                  -
                  <a
                    :href="report.imageUrl"
                    target="_blank"
                  >
                    <v-icon color="grey">mdi-image</v-icon>
                  </a>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="8"
      >
        <base-material-card
          color="green darken-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Dados dos relatórios
            </div>
          </template>

          <v-simple-table>
            <thead>
              <tr>
                <th class="primary--text text-center">
                  Data
                </th>
                <th class="primary--text text-center">
                  Recuperados
                </th>
                <th class="primary--text text-center">
                  Isolamento
                </th>
                <th class="primary--text text-center">
                  Internados
                </th>
                <th class="primary--text text-center">
                  Internados UTI
                </th>
                <th class="primary--text text-center">
                  Óbitos
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="reportData in reportsData"
                :key="reportData.date"
              >
                <td
                  class="text-center"
                >
                  {{ reportData.date | date }}
                </td>
                <td
                  class="text-center"
                >
                  {{ reportData.recuperados }}
                </td>
                <td
                  class="text-center"
                >
                  {{ reportData.isolamento }}
                </td>
                <td
                  class="text-center"
                >
                  {{ reportData.internados }}
                </td>
                <td
                  class="text-center"
                >
                  {{ reportData.internadosUti }}
                </td>
                <td
                  class="text-center"
                >
                  {{ reportData.obitos }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </base-material-card>
      </v-col>
    </v-row>

    <!-- Process Report Dialog -->
    <v-dialog
      v-model="processReportDialog"
      transition="dialog-bottom-transition"
      max-width="70%"
    >
      <v-card>
        <v-card-title class="purple darken-3 pb-4">
          <span class="white--text text-h3 font-weight-light">Inserir dados do relatório</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="7"
              >
                <img
                  :src="currentReport.imageUrl"
                  width="100%"
                >
              </v-col>
              <v-col
                cols="12"
                sm="5"
              >
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <v-text-field
                        v-model="currentData.date"
                        label="Data"
                        @keyup.enter="$refs.isolamento.focus()"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      class="mt-n8"
                    >
                      <v-text-field
                        ref="isolamento"
                        v-model="currentData.isolamento"
                        label="Isolamento"
                        @keyup.enter="$refs.internados.focus()"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      class="mt-n8"
                    >
                      <v-text-field
                        ref="internados"
                        v-model="currentData.internados"
                        label="Internados"
                        @keyup.enter="$refs.internadosUti.focus()"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      class="mt-n8"
                    >
                      <v-text-field
                        ref="internadosUti"
                        v-model="currentData.internadosUti"
                        label="Internados UTI"
                        @keyup.enter="$refs.recuperados.focus()"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      class="mt-n8"
                    >
                      <v-text-field
                        ref="recuperados"
                        v-model="currentData.recuperados"
                        label="Recuperados"
                        @keyup.enter="$refs.obitos.focus()"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      class="mt-n8"
                    >
                      <v-text-field
                        ref="obitos"
                        v-model="currentData.obitos"
                        label="Óbitos"
                        @keyup.enter="saveReportData"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="grey lighten-2 py-4">
          <v-checkbox
            v-model="openNextReportOnSave"
            label="Abrir próximo relatório ao salvar"
          />
          <v-spacer />
          <v-btn
            color="grey darken-3"
            text
            @click="closeReport"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success darken-1"
            @click="saveReportData"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    name: 'Admin',

    filters: {
      date: function (value) {
        if (!value) return null

        const year = value.substr(0, 4)
        const month = value.substr(5, 2)
        const day = value.substr(8, 2)

        return day + '/' + month + '/' + year
      },

      time: function (value) {
        if (!value) return null

        const hour = value.substr(11, 2)
        const minutes = value.substr(14, 2)

        return hour + ':' + minutes
      },
    },

    data () {
      return {
        reports: [],
        currentReport: {},
        currentReportIndex: null,
        processReportDialog: false,
        openNextReportOnSave: true,
        reportsData: [],
        currentData: {
          isolamento: null,
          internados: null,
          internadosUti: null,
          recuperados: null,
          obitos: null,
        },
      }
    },

    created () {
      const db = this.$firebase.firestore()

      const reports = db.collection('reports')
        .where('processed', '==', false)
        .orderBy('date')
      reports.get().then(snapshot => {
        snapshot.forEach(document => {
          this.reports.push(document.data())
          this.reports.reverse()
        })
      })

      const reportsData = db.collection('reportsData')
        .orderBy('date', 'desc')
      reportsData.get().then(snapshot => {
        snapshot.forEach(document => {
          this.reportsData.push(document.data())
        })
      })
    },

    methods: {
      loadReport (index) {
        this.clearCurrentData()
        this.setCurrentReport(index)
        this.processReportDialog = true
        setTimeout(() => {
          this.$refs.isolamento.focus()
        }, 250)
      },

      closeReport () {
        this.processReportDialog = false
        this.clearCurrentReport()
        this.clearCurrentData()
      },

      setCurrentReport (index) {
        this.clearCurrentData()
        this.clearCurrentReport()
        this.currentReport = this.reports[index]
        this.currentReportIndex = index
        this.currentData.date = this.$options.filters.date(this.currentReport.date)
      },

      clearCurrentReport () {
        this.currentReportIndex = null
        this.currentReport = {}
      },

      clearCurrentData () {
        this.currentData = {
          isolamento: null,
          internados: null,
          internadosUti: null,
          recuperados: null,
          obitos: null,
        }
      },

      saveReportData () {
        const db = this.$firebase.firestore()

        const data = Object.assign(this.currentData, {
          ref: this.currentReport.date,
          date: this.formatDateToDatabase(this.currentData.date),
        })

        const reportsData = db.collection('reportsData')
        reportsData.doc(data.date).set(data)
          .then(() => {
            return this.setReportAsProcessed(this.currentReport)
          })
          .then(() => {
            this.reportsData.unshift(data)
            this.reports.splice(this.currentReportIndex, 1)
            if (this.openNextReportOnSave) {
              this.loadReport(this.currentReportIndex)
            } else {
              this.processReportDialog = false
            }
          })
      },

      setReportAsProcessed (report) {
        report.processed = true

        const db = this.$firebase.firestore()
        const reports = db.collection('reports')

        return reports.doc(report.date).set(report)
      },

      formatDateToDatabase (value) {
        const day = value.substr(0, 2)
        const month = value.substr(3, 2)
        const year = value.substr(6, 4)

        return year + '-' + month + '-' + day
      },
    },
  }
</script>
